import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Container, Row, Col, Alert } from 'react-bootstrap';
import Button from 'components/Form/Button/Button';
import Input from 'components/Form/Input/Input';

// Constants
import { ROUTES } from 'constants/Constants';

// Context
import GlobalContext from 'contexts/Global.context';

// Interfaces
import { RegisterFormType } from 'interfaces/Register.types';

// Styles
import styles from './RegisterForm.module.scss';

interface Props {
  registerFormData: RegisterFormType;
  registerFormErrors: RegisterFormType;
  isNextStepDisabled: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  apiError?: string;
  goToNextStep: () => void;
}

const RegisterForm: React.FunctionComponent<Props> = ({
  registerFormData,
  registerFormErrors,
  isNextStepDisabled,
  handleInputChange,
  loading,
  apiError,
  goToNextStep,
}: Props) => {
  const { variantDetails } = useContext(GlobalContext);
  const RegisterBG = `${variantDetails?.assets_url}/register.png`;
  return (
    <Container className={`${styles.authPage}`} fluid>
      <Container className={styles.authPageWrap}>
        <Row className="align-content-center">
          <Col
            className={`d-xl-block d-lg-block d-md-none d-sm-none d-none ${styles.authIntro}`}
            xl={6}
            lg={6}
            md={6}
            sm={12}
          >
            <img
              src={RegisterBG}
              className={`img-fluid ${styles.authBG}`}
              alt="Login"
            />
            <div className={styles.authTitleData}>
              <div className={styles.authTitle}>Sign Up</div>
              <div className={styles.authSubTitle}>
                {variantDetails?.main_text || 'Welcome to your professional community'}
              </div>
            </div>
          </Col>
          <Col className={`${styles.authForm}`} xl={6} lg={6} md={12} sm={12}>
            <div className={styles.authFormWrap}>
              <div className={`pr-5 ${styles.authFormTitle}`}>
                Almost finished, just need some final information so we can
                create a proper profile.
              </div>

              <div className={styles.authInput}>
                <div className={styles.authLabel}>
                  E-Mail Address <span className={styles.required}>*</span>
                </div>
                <div className={`${styles.requiredFieldsNote} text-right`}>
                  <span>* </span>= Required Fields
                </div>
                <Input
                  name="email"
                  type="text"
                  value={registerFormData.email}
                  onChange={handleInputChange}
                  className={registerFormErrors.email ? styles.errorInput : ""}
                />
                {registerFormErrors.email && (
                  <div className={styles.error}>{registerFormErrors.email}</div>
                )}
              </div>

              <div className={styles.authInput}>
                <div className={styles.authLabel}>
                  Username <span className={styles.required}>*</span>
                </div>
                <Input
                  name="userName"
                  type="text"
                  value={registerFormData.userName}
                  onChange={handleInputChange}
                  className={
                    registerFormErrors.userName ? styles.errorInput : ""
                  }
                />
                {registerFormErrors.userName && (
                  <div className={styles.error}>
                    {registerFormErrors.userName}
                  </div>
                )}
              </div>

              <div className={styles.authInput}>
                <div className={styles.authLabel}>
                  Password <span className={styles.required}>*</span>
                </div>
                <Input
                  name="password"
                  type="password"
                  value={registerFormData.password}
                  onChange={handleInputChange}
                  className={
                    registerFormErrors.password ? styles.errorInput : ""
                  }
                />
                {registerFormErrors.password && (
                  <div className={styles.error}>
                    {registerFormErrors.password}
                  </div>
                )}
              </div>

              <div className={styles.authInput}>
                <div className={styles.authLabel}>
                  Confirm Password <span className={styles.required}>*</span>
                </div>
                <Input
                  name="confirmPassword"
                  type="password"
                  value={registerFormData.confirmPassword}
                  onChange={handleInputChange}
                  className={
                    registerFormErrors.confirmPassword ? styles.errorInput : ""
                  }
                />
                {registerFormErrors.confirmPassword && (
                  <div className={styles.error}>
                    {registerFormErrors.confirmPassword}
                  </div>
                )}
              </div>

              <div className={styles.authButton}>
                <Button
                  disabled={isNextStepDisabled}
                  type="large w-100"
                  label={loading ? "loading..." : "Create An Account"}
                  onClick={goToNextStep}
                />
              </div>

              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className={styles.signUpTerms}
                >
                  By signing up you are agreeing to our{" "}
                  <a
                    href={ROUTES.TERMS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                </Col>
              </Row>

              <div className={styles.authSubText}>
                Already have an account?<Link to={ROUTES.LOGIN}>Login</Link>
              </div>
              {apiError && <Alert variant="danger">{apiError}</Alert>}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default RegisterForm;
